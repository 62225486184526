import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Col } from 'react-bootstrap';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { UpdateAstrologerProfile, errorMessage } from '../../../Redux/Slice';


const EditAstrologerProfile = ({ show, closeEdit, astrologer }) => {
    const dispatch = useDispatch();
    const { errors } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem('admin-auth-token')

    const formik = useFormik({
        initialValues: {
            astrologerId: astrologer?.data?.data?.astrologer?.amc_id,
            astrologerRate: astrologer?.data?.data?.astrologer?.myrate,
            astrologerShare: astrologer?.data?.data?.astrologer?.myshare_percentage,
            amcShare: 100 - Number(astrologer?.data?.data?.astrologer?.myshare_percentage),
        },
        onSubmit: async (values) => {
            let UpdateDetails = {
                astrologerId: astrologer?.data?.data?.astrologer?.id,
                amc_id: values?.astrologerId,
                astrologer_rate: values?.astrologerRate,
                astrologer_share: values?.astrologerShare,
                amc_share: values?.amcShare
            }
            dispatch(UpdateAstrologerProfile({ token, UpdateDetails }));
        },
        validate: (values) => {
            const errors = {};
            const formatRegex = /^AMCA[A-Za-z0-9]{4}$/;

            if (!values.astrologerId) {
                errors.astrologerId = "Requried*";
            }
            else if (!formatRegex.test(values.astrologerId)) {
                errors.astrologerId = 'Invalid format. It should be in the format AMCAXXXX.';
            }
            if (!values.astrologerRate) {
                errors.astrologerRate = "Required*";
            }
            if (!values.astrologerShare) {
                errors.astrologerShare = "Required*";
            }
            if (!values.amcShare) {
                errors.amcShare = "Required*";
            } else if (values.astrologerShare + values.amcShare != 100) {
                errors.amcShare = "please provide efficient share percentage*";
            }
            return errors;
        }
    })

    useEffect(() => {
        if (errors?.status === 400) {
            Swal.fire({
                title: errors?.data?.message,
                icon: "error",
                iconColor: "#CA0505",
                confirmButtonColor: "#CA0505",
                confirmButtonText: "Okay",
                timer: 5000
            }).then((result) => {
                dispatch(errorMessage({ errors: '' }));
            })
        }
    }, [dispatch, errors]);

    return (
        <>
            <Modal
                show={show}
                onHide={() => closeEdit(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Astrologer Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col className='astrologer-details'>
                        <Col lg={12} className='astrologer-form'>
                            <h5 >
                                Astrologer Id
                                <span className='text-red'>*</span>
                                <span className='note'>(ex : AMCAXXXX)</span>
                            </h5>
                            <input
                                type='text'
                                name='astrologerId'
                                id='astrologerId'
                                value={formik.values.astrologerId}
                                onChange={formik.handleChange}
                            />
                            <p className={"error__feedback"}>{formik.errors.astrologerId}</p>
                        </Col>
                        <Col lg={12} className='astrologer-form'>
                            <h5 >
                                Astrologer Rate
                                <span className='text-red'>*</span>
                                <span className='note'>(per minute)</span>
                            </h5>
                            <input
                                type='number'
                                name='astrologerRate'
                                id='astrologerRate'
                                value={formik.values.astrologerRate}
                                onChange={formik.handleChange}
                            />
                            <p className={"error__feedback"}>{formik.errors.astrologerRate}</p>
                        </Col>
                        <Col lg={12} className='d-flex justify-content-between astrologer-form'>
                            <Col lg={5}>
                                <h5 >
                                    Astrologer Share
                                    <span className='text-red'>*</span>
                                    <span className='note'>(%)</span>
                                </h5>
                                <input
                                    type='number'
                                    name='astrologerShare'
                                    id='astrologerShare'
                                    value={formik.values.astrologerShare}
                                    onChange={formik.handleChange}
                                />
                                <p className={"error__feedback"}>{formik.errors.astrologerShare}</p>
                            </Col>
                            <Col lg={5}>
                                <h5 >
                                    AMC Share
                                    <span className='text-red'>*</span>
                                    <span className='note'>(%)</span>
                                </h5>
                                <input
                                    type='number'
                                    name='amcShare'
                                    id='amcShare'
                                    value={formik.values.amcShare}
                                    onChange={formik.handleChange}
                                />
                                <p className={"error__feedback"}>{formik.errors.amcShare}</p>
                            </Col>
                        </Col>
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={formik.handleSubmit}
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EditAstrologerProfile;