import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';

import MainPagetitle from '../../layouts/MainPagetitle';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetPaymentHistory } from '../../../Redux/Slice';

function PaymentHistory() {
    const dispatch = useDispatch();
    const { PaymentHistory, Loading } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem('admin-auth-token');
    const { id } = useParams();

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if (currentPage > 0) {
            dispatch(GetPaymentHistory({ token, userId: id, page: currentPage ? currentPage : 1 }));
        }
    }, [dispatch, token, id, currentPage])

    useEffect(() => {
        window.scrollTo(0, 0);
        if (PaymentHistory?.data?.data?.pagination?.currentpage) {
            setCurrentPage(PaymentHistory?.data?.data?.pagination?.currentpage);
        }
    }, [PaymentHistory]);

    const handlePageClick = (event) => {
        const page = Number(event.selected) + 1;
        dispatch(GetPaymentHistory({ token, userId: id, page }))
    }

    return (
        <>
            <MainPagetitle pageTitle="Payment Transactions" parentTitle="Home" />

            <Container fluid>
                <Row className='d-flex list-main'>
                    {Loading ?
                        <>
                            <div class="wrapper">
                                <div class="page-loader">
                                    <div class="loading one"></div>
                                    <div class="loading two"></div>
                                    <div class="loading three"></div>
                                    <div class="loading four"></div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {
                                PaymentHistory?.data?.data?.tranz?.length > 0 ?
                                    <Col lg={12} className='list-data mt-4 mb-3 p-4'>
                                        <div className='d-flex justify-content-between p-3' >
                                            <h4>AMC ID : {PaymentHistory?.data?.data?.userDetails?.amc_id}
                                            </h4>
                                            <h4>User Name : {PaymentHistory?.data?.data?.userDetails?.name}
                                            </h4>
                                        </div>
                                        <table key='list' className="table-140" >
                                            <tr className='transaction-heading'>
                                                <th>Date & Time</th>
                                                <th>Payment ID</th>
                                                <th>Deposit Amount</th>
                                                <th>Type</th>
                                                <th >Status</th>
                                                <th>Total Amount (Gst 18%)</th>
                                                <th>Invoice Id</th>
                                                <th>Invoice Url</th>
                                            </tr>
                                            {PaymentHistory?.data?.data?.tranz?.map((data, index) => {
                                                let indianTime = new Date(data?.createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });

                                                return (
                                                    <>
                                                        <tr className='transaction-data'>
                                                            <td>{indianTime}</td>
                                                            <td>{data.payment_id}</td>
                                                            <td className={data.status === 'authorized' || data.status === 'captured' ? null : 'payment-failed'}>₹ {data.deposit_amount}
                                                                <span className={data?.bonus > 0 ? 'payment-success' : null} > {data?.bonus > 0 ? <>(+{data?.bonus})</> : null}</span>
                                                            </td>
                                                            <td>{data.Payment_method}</td>
                                                            <td className={data.status === 'authorized' || data.status === 'captured' ? 'payment-success' : 'payment-failed'}>
                                                                {data.status === 'authorized' || data.status === 'captured' ? 'success' : data?.status}
                                                            </td>
                                                            <td className={data.status === 'authorized' || data.status === 'captured' ? null : 'payment-failed'}>
                                                                ₹ {data.total_amount}
                                                            </td>
                                                            <td>{data.invoice_id ? data.invoice_id : "none"}</td>
                                                            <td>{data.invoice_url ? <a href={data.invoice_url} className='text-primary'>download</a> : "none"}</td>
                                                        </tr>
                                                    </>
                                                )
                                            })}
                                        </table>
                                    </Col>
                                    :
                                    <div className='no-data pt-5'>
                                        <p>No sufficient data available!</p>
                                    </div>
                            }

                            {PaymentHistory?.data?.data?.pagination?.totalPage > 1 ?
                                <>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=">"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={3}
                                        pageCount={PaymentHistory?.data?.data?.pagination?.totalPage}
                                        forcePage={PaymentHistory?.data?.data?.pagination?.currentpage - 1}
                                        previousLabel="<"
                                        renderOnZeroPageCount={null}
                                        className='custom-pagination'
                                    />
                                </>
                                : null
                            }
                        </>
                    }
                </Row>
            </Container >
        </>
    )
}

export default PaymentHistory;