import { Container, Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';

import MainPagetitle from '../../layouts/MainPagetitle';
import UserPreview from './UserPreview'
import FilterAstrologer from './FilterAstrologer';

import { IoClose } from "react-icons/io5";
import { FaFilter, FaSearch } from "react-icons/fa";
// import { MdSort } from "react-icons/md";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetAllUsers } from '../../../Redux/Slice';

const UsersList = () => {
    const dispatch = useDispatch();
    const { Users, Loading } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem('admin-auth-token');
    let sortBy = JSON.parse(localStorage.getItem("sortby"));
    let filter = JSON.parse(localStorage.getItem("filter"));

    const [show, setShow] = useState(false);
    const [previewData, setPreviewData] = useState();
    const [currentPage, setCurrentPage] = useState(Users?.data?.pagination?.currentpage);
    const pageLimit = window.innerWidth < 600 ? 15 : 25;
    const [searchValue, setSearchValue] = useState(null);
    const [showFilter_or_sort, setShowFilter_or_sort] = useState({
        status: false,
        type: ''
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const closeFilter = (value) => {
        setShowFilter_or_sort({ ...showFilter_or_sort, status: value });
    }

    useEffect(() => {
        dispatch(GetAllUsers({ token, page: 1, pageLimit }));
        if (sortBy) {
            localStorage.removeItem("sortby");
        }
    }, [dispatch, token, sortBy]);

    useEffect(() => {
        window.scrollTo(0, 0);
        setCurrentPage(Users?.data?.pagination?.currentpage);
    }, [Users]);

    useEffect(() => {
        if (searchValue) {
            dispatch(GetAllUsers({ token, page: currentPage, pageLimit, search: searchValue }));
        }
        else if (searchValue === '') {
            dispatch(GetAllUsers({ token: token, page: 1, pageLimit }));
        }
    }, [dispatch, token, currentPage, searchValue])

    const handlePageClick = (event) => {
        const page = Number(event.selected) + 1;
        dispatch(GetAllUsers({ token, page, pageLimit }));
    }

    const PreviewCall = (data) => {
        setPreviewData(data)
        handleShow()
    }

    return (
        <>
            {
                showFilter_or_sort ?
                    <FilterAstrologer
                        show={showFilter_or_sort}
                        close={closeFilter}
                        sortBy={sortBy}
                        filter={filter}
                        pageLimit={pageLimit}
                    />
                    : null
            }

            <MainPagetitle pageTitle="Users" parentTitle="Home" />

            <Container fluid>
                <Row className='d-flex user-list'>
                    <Col lg={12} className='list-main'>
                        <Col lg={12} sm={12} className='d-flex py-5'>
                            <Col lg={3} sm={3} className=''>
                                <h2 className='fw-600'>Users</h2>
                            </Col>
                            <Col lg={9} sm={9} className="d-flex justify-content-end gap-3 filter-nav">
                                <div className="d-flex text-white search-option">
                                    <input
                                        type="text"
                                        value={searchValue}
                                        onChange={(e) => { setSearchValue(e.target.value) }}
                                        placeholder='Search AMC ID, Phone Number, Name'
                                    />
                                    {
                                        searchValue ?
                                            <div className="d-flex align-items-center p-2 cancel-btn" onClick={() => { setSearchValue('') }}>
                                                <IoClose />
                                            </div>
                                            :
                                            <div className="d-flex justify-content-center p-2 search-btn">
                                                <FaSearch />
                                            </div>
                                    }
                                </div>
                                <button
                                    className={`filter-btn`}
                                    onClick={() => { setShowFilter_or_sort({ status: true, type: 'filter' }) }}
                                >
                                    <FaFilter className='icon' />
                                    <p>Filter</p>
                                </button>
                                {/* <button
                                    className={`filter-btn`}
                                // onClick={() => { setShowFilter_or_sort({ status: true, type: 'sort' }) }}
                                >
                                    <MdSort className='icon' />
                                    <p>Sort by</p>
                                </button> */}
                            </Col>
                        </Col>
                        {Loading ?
                            <>
                                <div class="wrapper">
                                    <div class="page-loader">
                                        <div class="loading one"></div>
                                        <div class="loading two"></div>
                                        <div class="loading three"></div>
                                        <div class="loading four"></div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                {
                                    Users?.data?.data?.length > 0 ?

                                        <Col lg={12} className='list-data mb-3'>
                                            <table className={show ? "table-60" : "table-100"} >
                                                <tr>
                                                    <th>S No</th>
                                                    <th>Date of Join</th>
                                                    <th>AMC ID</th>
                                                    <th>Name</th>
                                                    {/* <th className={`${show ? "hide-list" : null} responsive-hide`}>Email</th> */}
                                                    <th className='responsive-hide'>Phone</th>
                                                    <th></th>
                                                </tr>
                                                {Users?.data?.data?.map((data, index) => {
                                                    let indianTime = new Date(data?.profileDetails?.createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        Users?.data?.pagination?.currentpage === 1 ?
                                                                            <>
                                                                                {index + 1}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {((Users?.data?.pagination?.currentpage - 1) * pageLimit) + (index + 1)}
                                                                            </>
                                                                    }
                                                                </td>
                                                                <td>{indianTime}</td>
                                                                <td>{data?.profileDetails?.amc_id}</td>
                                                                <td>{data?.profileDetails?.name}</td>
                                                                {/* <td className={`${show ? "hide-list" : null} responsive-hide`}>{data?.profileDetails?.email}</td> */}
                                                                <td className='responsive-hide'>+{data?.profileDetails?.phone}</td>
                                                                <td className='view' onClick={() => PreviewCall(data)}>view details</td>
                                                            </tr>
                                                        </>
                                                    )
                                                })}
                                            </table>
                                        </Col>
                                        :
                                        <>
                                            <div className='no-data pt-5'>
                                                <p>No sufficient data available!</p>
                                            </div>
                                        </>
                                }
                            </>
                        }

                        <UserPreview show={show} handleClose={handleClose} data={previewData} />

                    </Col>

                    {Users?.data?.pagination?.totalPage > 1 ?
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            pageCount={Users?.data?.pagination?.totalPage}
                            forcePage={Users?.data?.pagination?.currentpage - 1}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            className='custom-pagination'
                        />
                        : null
                    }

                </Row>
            </Container >
        </>
    )
}

export default UsersList;