import { Container, Row, Col } from 'react-bootstrap';
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { GetAllNotifications, UpdateNotificationSeenStatus } from "../../../Redux/Slice";

import MainPagetitle from "../../layouts/MainPagetitle";

function Notifications() {
    const { success, errors, Loading, Notifications } = useSelector((state) => state.Astromind);
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const token = localStorage.getItem("admin-auth-token");

    useEffect(() => {
        dispatch(GetAllNotifications({ token }));
    }, [dispatch, token])

    const handleUpdateSeenStatus = (item) => {
        if (!(item?.isSeen)) {
            dispatch(UpdateNotificationSeenStatus({ token, id: item?.id }));
        }
        navigate(item?.redirectUrl);
    }

    return (
        <>
            <MainPagetitle pageTitle="Notifications" parentTitle="Home" />

            <Container fluid className='pt-100 mb-5'>
                <Container className='mt-0'>
                    <Row>
                        {Loading ?
                            <>
                                <div class="wrapper">
                                    <div class="page-loader">
                                        <div class="loading one"></div>
                                        <div class="loading two"></div>
                                        <div class="loading three"></div>
                                        <div class="loading four"></div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                {
                                    Notifications?.data?.data?.notifications?.length > 0 ?
                                        <>
                                            {
                                                Notifications?.data?.data?.notifications?.map((item, i) => (
                                                    <>
                                                        <Col
                                                            lg={12}
                                                            className={`notification-list ${item?.isSeen ? null : "not-seen"} mb-3`}
                                                            onClick={() => { handleUpdateSeenStatus(item) }}
                                                        >
                                                            <h6>{item?.title}</h6>
                                                            <p>{item?.description}</p>
                                                            <div className='notify-date'>
                                                                {new Date(item?.createdAt).toLocaleString(
                                                                    "en-US",
                                                                    {
                                                                        month: "short",
                                                                        day: "2-digit",
                                                                        year: "numeric",
                                                                        hour: "2-digit",
                                                                        minute: "2-digit",
                                                                        hour12: true,
                                                                    }
                                                                )}
                                                            </div>
                                                        </Col>
                                                    </>
                                                ))
                                            }
                                        </>
                                        :
                                        <div className='no-data pt-5'>
                                            <p>No sufficient data available!</p>
                                        </div>
                                }

                            </>
                        }
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default Notifications;