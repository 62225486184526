import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Container, Row, Col, Collapse } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Pagination from 'react-bootstrap/Pagination';

import Select from 'react-select';
import Swal from 'sweetalert2';

import MainPagetitle from '../../layouts/MainPagetitle';

import { RiDeleteBin6Fill } from "react-icons/ri";
import { FaTrashRestore } from "react-icons/fa";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetAllBlogs, successMessage, errorMessage, DeleteBlog, RestoreOrDeleteBlog } from '../../../Redux/Slice';

const options = [
    { value: 'Published', label: 'Published' },
    { value: 'Draft', label: 'Draft' }
]

function BlogList() {
    const dispatch = useDispatch();
    const { AdminProfile, Blogs, Loading, success, errors } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem('admin-auth-token');

    const [currentPage, setCurrentPage] = useState(Blogs?.data?.data?.pagination?.currentpage);

    const [search, setSearch] = useState('');
    const [filter, setFtilter] = useState('');
    const [date, setDate] = useState('');
    const [type, setType] = useState('all');

    useEffect(() => {
        setCurrentPage(Blogs?.data?.data?.pagination?.currentpage);
    }, [Blogs]);

    useEffect(() => {
        if (type) {
            dispatch(GetAllBlogs({ token, page: 1, type }));
        }
        if (search || filter || date) {
            let filterValue = filter?.value ? filter?.value : '';
            dispatch(GetAllBlogs({ token, page: 1, search, date, filterValue, type }));
        }
        if (success) {
            Swal.fire({
                title: success?.data?.message,
                icon: "success",
                iconColor: "#36AA00",
                confirmButtonColor: "#36AA00",
                confirmButtonText: "Okay",
                timer: 5000
            }).then((result) => {
                dispatch(successMessage(''));
                dispatch(GetAllBlogs({ token, page: 1, type }));
            })
        }
        else if (errors) {
            Swal.fire({
                title: errors?.data?.message,
                icon: "error",
                iconColor: "#36AA00",
                confirmButtonColor: "#36AA00",
                confirmButtonText: "Okay",
                timer: 5000
            }).then((result) => {
                dispatch(errorMessage(''));
                dispatch(GetAllBlogs({ token, page: 1, type }));
            })
        }
    }, [dispatch, token, type, search, filter, date, success, errors]);

    const handleDelete = (id) => {
        let blogId = id;
        Swal.fire({
            title: "Are you sure want to Delete your Blog?",
            icon: "warning",
            iconColor: "#CA0505",
            showCancelButton: true,
            cancelButtonColor: "#025BFD",
            confirmButtonColor: "#CA0505",
            confirmButtonText: "Delete"
        }).then((result) => {
            if (result?.isConfirmed) {
                dispatch(DeleteBlog({ token, blogId }));
            }
        });
    }

    const handleDeleteOrRestore = (id, status) => {
        console.log(id);
        console.log(status);
        let blogId = id;
        Swal.fire({
            title: `Are you sure want to ${status === 'delete' ? "permanently Delete" : "Restore"} your blog?`,
            icon: "warning",
            iconColor: "#CA0505",
            showCancelButton: true,
            cancelButtonColor: "#025BFD",
            confirmButtonColor: "#CA0505",
            confirmButtonText: `${status === 'delete' ? "Delete" : "Restore"}`
        }).then((result) => {
            if (result?.isConfirmed) {
                dispatch(RestoreOrDeleteBlog({ token, blogId, status }));
            }
        });
    }

    const resetFilter = () => {
        setSearch('');
        setFtilter('');
        setDate('');
        dispatch(GetAllBlogs({ token, page: 1, type }));
    }

    const changePage = (value) => {
        dispatch(GetAllBlogs({ token, page: value, type }))
    }

    const nextPage = () => {
        const next = Number(currentPage) + 1;
        if (next <= Blogs?.data?.data?.pagination?.totalPage) {
            dispatch(GetAllBlogs({ token, page: next, type }));
        }
    }

    const previousPage = () => {
        const previousPage = Number(currentPage) - 1;
        if (previousPage >= 1) {
            dispatch(GetAllBlogs({ token, page: previousPage, type }));
        }
    }

    return (
        <>
            <MainPagetitle pageTitle="Blogs" parentTitle="Home" />
            <Container fluid >
                <Row >
                    <Col xl={12} >
                        <div className="filter cm-content-box box-primary add-offer-card">
                            <div className="content-title">
                                <div className="cpa">
                                    <i className="fas fa-filter me-2"></i>Filter
                                </div>
                            </div>
                            <div className="cm-content-body form excerpt">
                                <div className="card-body">
                                    <Row className="filter-row justify-content-around">
                                        <Col xl={3} sm={6} >
                                            <input
                                                type="text"
                                                className="form-control mb-xl-0 mb-3"
                                                placeholder="Title"
                                                name='search'
                                                value={search}
                                                onChange={(e) => { setSearch(e.target.value) }}
                                            />
                                        </Col>
                                        <Col xl={3} sm={6} className="mb-xl-0 mb-3">
                                            <Select
                                                isSearchable={false}
                                                options={options}
                                                className="custom-react-select"
                                                value={filter}
                                                onChange={(value) => { setFtilter(value) }}
                                            />
                                        </Col>
                                        <Col xl={3} sm={6} >
                                            <input
                                                type="date"
                                                name="date"
                                                className="form-control mb-xl-0 mb-3"
                                                value={date}
                                                onChange={(e) => { setDate(e.target.value) }}
                                            />
                                        </Col>
                                        <Col xl={1} sm={6} >
                                            <button
                                                className="btn btn-danger light"
                                                title="Click here to remove filter"
                                                type="button"
                                                onClick={() => resetFilter()}
                                            >Remove
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>

                        <div className="filter cm-content-box box-primary add-offer-card">
                            <div className={`content-title`}>
                                <div className="cpa">
                                    <i className="fas fa-file-word me-2"></i>Blog Content List
                                </div>
                            </div>
                            <Collapse>
                                {Loading ?
                                    <>
                                        <div class="wrapper">
                                            <div class="page-loader">
                                                <div class="loading one"></div>
                                                <div class="loading two"></div>
                                                <div class="loading three"></div>
                                                <div class="loading four"></div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {
                                            AdminProfile?.data?.data?.role?.rolename == "Admin" ?
                                                <>
                                                    <div className="cm-content-body form excerpt">
                                                        <Tabs
                                                            id="controlled-tab-example"
                                                            activeKey={type}
                                                            onSelect={(k) => setType(k)}
                                                            className="blog-tabs mb-3"
                                                        >
                                                            <Tab eventKey="all" title="All">
                                                                {
                                                                    Blogs?.data?.data?.blogs?.length > 0 ?
                                                                        <div className="card-body py-3">
                                                                            <div className="table-responsive">
                                                                                <div id="content_wrapper" className="dataTables_wrapper no-footer">
                                                                                    <table className="table table-responsive-lg table-striped table-condensed flip-content">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th className='text-black'>S.No</th>
                                                                                                <th className='text-black'>Title</th>
                                                                                                <th className='text-black'>Status</th>
                                                                                                <th className='text-black'>Date</th>
                                                                                                <th className='text-black'>View Details</th>
                                                                                                <th className="text-black text-end">Actions</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {Blogs?.data?.data?.blogs?.map((blog, i) => (
                                                                                                <tr key={i}>
                                                                                                    <td>{i + 1}</td>
                                                                                                    <td className='blog-titleData'>{blog?.blogtitle}</td>
                                                                                                    <td>{blog?.contentType}</td>
                                                                                                    <td>{blog?.publishedOn}</td>
                                                                                                    <td className='view-details'><Link to={`/view-blog/${blog?.id}`}>view</Link></td>
                                                                                                    <td className='text-end'>
                                                                                                        <Link to={`/edit-blog/${blog?.id}`} className="btn btn-warning btn-sm content-icon me-1">
                                                                                                            <i className="fa fa-edit"></i>
                                                                                                        </Link>
                                                                                                        <Link className="btn btn-danger btn-sm content-icon ms-1"
                                                                                                            onClick={() => handleDelete(blog?.id)}
                                                                                                        >
                                                                                                            <RiDeleteBin6Fill />
                                                                                                        </Link>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className='no-data pt-5'>
                                                                            <p>No sufficient data available!</p>
                                                                        </div>
                                                                }
                                                            </Tab>
                                                            <Tab eventKey="approval" title="Approval">
                                                                {
                                                                    Blogs?.data?.data?.blogs?.length > 0 ?
                                                                        <div className="card-body py-3">
                                                                            <div className="table-responsive">
                                                                                <div id="content_wrapper" className="dataTables_wrapper no-footer">
                                                                                    <table className="table table-responsive-lg table-striped table-condensed flip-content">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th className='text-black'>S.No</th>
                                                                                                <th className='text-black'>Title</th>
                                                                                                <th className='text-black'>Status</th>
                                                                                                <th className='text-black'>Date</th>
                                                                                                <th className='text-black'>View Details</th>
                                                                                                <th className="text-black text-end">Actions</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {Blogs?.data?.data?.blogs?.map((blog, i) => (
                                                                                                <tr key={i}>
                                                                                                    <td>{i + 1}</td>
                                                                                                    <td className='blog-titleData'>{blog?.blogtitle}</td>
                                                                                                    <td>{blog?.contentType}</td>
                                                                                                    <td>{blog?.publishedOn}</td>
                                                                                                    <td className='view-details'><Link to={`/view-blog/${blog?.id}`}>view</Link></td>
                                                                                                    <td className='text-end'>
                                                                                                        <Link to={`/edit-blog/${blog?.id}`} className="btn btn-warning btn-sm content-icon me-1">
                                                                                                            <i className="fa fa-edit"></i>
                                                                                                        </Link>
                                                                                                        <Link className="btn btn-danger btn-sm content-icon ms-1"
                                                                                                            onClick={() => handleDelete(blog?.id)}
                                                                                                        >
                                                                                                            <RiDeleteBin6Fill />
                                                                                                        </Link>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className='no-data pt-5'>
                                                                            <p>No sufficient data available!</p>
                                                                        </div>
                                                                }
                                                            </Tab>
                                                            <Tab eventKey="delete" title="Deleted">
                                                                {
                                                                    Blogs?.data?.data?.blogs?.length > 0 ?
                                                                        <div className="card-body py-3">
                                                                            <div className="table-responsive">
                                                                                <div id="content_wrapper" className="dataTables_wrapper no-footer">
                                                                                    <table className="table table-responsive-lg table-striped table-condensed flip-content">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th className='text-black'>S.No</th>
                                                                                                <th className='text-black'>Title</th>
                                                                                                <th className='text-black'>Status</th>
                                                                                                <th className='text-black'>Date</th>
                                                                                                <th className='text-black'>View Details</th>
                                                                                                <th className="text-black text-end">Actions</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {Blogs?.data?.data?.blogs?.map((blog, i) => (
                                                                                                <tr key={i}>
                                                                                                    <td>{i + 1}</td>
                                                                                                    <td className='blog-titleData'>{blog?.blogtitle}</td>
                                                                                                    <td>{blog?.contentType}</td>
                                                                                                    <td>{blog?.publishedOn}</td>
                                                                                                    <td className='view-details'><Link to={`/view-blog/${blog?.id}`}>view</Link></td>
                                                                                                    <td className='text-end'>
                                                                                                        {/* <Link to={`/edit-blog/${blog?.id}`} className="btn btn-warning btn-sm content-icon me-1">
                                                                        <i className="fa fa-edit"></i>
                                                                    </Link> */}
                                                                                                        <Link className="btn btn-success btn-sm content-icon ms-1"
                                                                                                            onClick={() => handleDeleteOrRestore(blog?.id, "restore")}
                                                                                                        >
                                                                                                            <FaTrashRestore />
                                                                                                        </Link>
                                                                                                        <Link className="btn btn-danger btn-sm content-icon ms-1"
                                                                                                            onClick={() => handleDeleteOrRestore(blog?.id, "delete")}
                                                                                                        >
                                                                                                            <RiDeleteBin6Fill />
                                                                                                        </Link>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className='no-data pt-5'>
                                                                            <p>No sufficient data available!</p>
                                                                        </div>
                                                                }
                                                            </Tab>
                                                        </Tabs>

                                                    </div>
                                                </>
                                                :
                                                <>
                                                    {
                                                        Blogs?.data?.data?.blogs?.length > 0 ?
                                                            <div className="card-body py-3">
                                                                <div className="table-responsive">
                                                                    <div id="content_wrapper" className="dataTables_wrapper no-footer">
                                                                        <table className="table table-responsive-lg table-striped table-condensed flip-content">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th className='text-black'>S.No</th>
                                                                                    <th className='text-black'>Title</th>
                                                                                    <th className='text-black'>Status</th>
                                                                                    <th className='text-black'>Date</th>
                                                                                    <th className='text-black'>View Details</th>
                                                                                    <th className="text-black text-end">Actions</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {Blogs?.data?.data?.blogs?.map((blog, i) => (
                                                                                    <tr key={i}>
                                                                                        <td>{i + 1}</td>
                                                                                        <td className='blog-titleData'>{blog?.blogtitle}</td>
                                                                                        <td>{blog?.contentType}</td>
                                                                                        <td>{blog?.publishedOn}</td>
                                                                                        <td className='view-details'><Link to={`/view-blog/${blog?.id}`}>view</Link></td>
                                                                                        <td className='text-end'>
                                                                                            <Link to={`/edit-blog/${blog?.id}`} className="btn btn-warning btn-sm content-icon me-1">
                                                                                                <i className="fa fa-edit"></i>
                                                                                            </Link>
                                                                                            <Link className="btn btn-danger btn-sm content-icon ms-1"
                                                                                                onClick={() => handleDelete(blog?.id)}
                                                                                            >
                                                                                                <RiDeleteBin6Fill />
                                                                                            </Link>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className='no-data pt-5'>
                                                                <p>No sufficient data available!</p>
                                                            </div>
                                                    }
                                                </>
                                        }
                                    </>
                                }

                            </Collapse>

                            {Blogs?.data?.data?.pagination?.totalPage > 1 ?
                                <Col lg={12} className='d-flex justify-content-center mt-5 pagination'>
                                    <Pagination className="px-4">
                                        <Pagination.Prev onClick={() => previousPage()} />

                                        {[...new Array(Blogs?.data?.data?.pagination?.totalPage)].map((arr, index) => {
                                            return (
                                                <Pagination.Item
                                                    onClick={() => changePage(index + 1)}
                                                    key={index + 1}
                                                    active={index + 1 === Number(currentPage)}
                                                >
                                                    {index + 1}
                                                </Pagination.Item>
                                            )
                                        })}
                                        <Pagination.Next onClick={() => nextPage()} />
                                    </Pagination>
                                </Col>
                                : null
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default BlogList;