import { Container, Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';
import Swal from 'sweetalert2';

import MainPagetitle from '../../layouts/MainPagetitle';
import ChangesApprovalPreview from './ChangesApprovalPreview';

import EmptyProfile from '../../../images/dashboard/empty-profile.png';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetAstrologersEditRequest, successMessage, errorMessage } from '../../../Redux/Slice';

function ChangesApprovalList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { AstrologersEditRequest, Loading, success, errors } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem('admin-auth-token');

    const [previewData, setPreviewData] = useState(null);
    const [requestData, setRequestData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setCurrentPage(AstrologersEditRequest?.data?.pagination?.currentpage);
    }, [AstrologersEditRequest]);

    const changePage = (value) => {
        dispatch(GetAstrologersEditRequest({ token: token, page: value }))
    }

    const nextPage = () => {
        const next = Number(currentPage) + 1;
        if (next <= AstrologersEditRequest?.data?.pagination?.totalPage) {
            dispatch(GetAstrologersEditRequest({ token: token, page: next }));
        }
    }

    const previousPage = () => {
        const previousPage = Number(currentPage) - 1;
        if (previousPage >= 1) {
            dispatch(GetAstrologersEditRequest({ token: token, page: previousPage }));
        }
    }

    useEffect(() => {
        dispatch(GetAstrologersEditRequest({ token: token, page: currentPage }))
    }, []);

    const previewBack = () => {
        setPreviewData(false);
    }

    const handlePreview = (item) => {
        setPreviewData(true);
        setRequestData(item)
    }

    useEffect(() => {
        if (success?.status === 200) {
            Swal.fire({
                title: success?.data?.message,
                icon: "success",
                iconColor: "#36AA00",
                confirmButtonColor: "#36AA00",
                confirmButtonText: "OK",
                timer: 5000,
            }).then(() => {
                dispatch(GetAstrologersEditRequest({ token: token, page: currentPage }));
                if (success?.data?.data?.isModified) {
                    dispatch(successMessage(''));
                }
                else {
                    previewBack();
                    dispatch(successMessage(''));
                }
            })
        }

        else if (errors) {
            Swal.fire({
                title: errors?.message,
                icon: "error",
                iconColor: "#CA0505",
                confirmButtonColor: "#CA0505",
                confirmButtonText: "Okay",
                timer: 5000,
            }).then(() => {
                dispatch(GetAstrologersEditRequest({ token: token, page: currentPage }));
                dispatch(errorMessage(' '));
            })
        }
    }, [dispatch, token, currentPage, success, errors]);

    return (
        <>
            {previewData ?
                <ChangesApprovalPreview
                    back={previewBack}
                    requestData={requestData}
                />
                :
                <Container fluid>
                    <Row className='d-flex'>
                        <Col lg={12} className='list-main'>
                            <MainPagetitle pageTitle="Changes Approval" parentTitle="Home" />
                        </Col>
                        <Col lg={12} className='d-flex justify-content-center gap-3 mt-5'>
                            <button
                                className='approval-btn bg-yellow text-white'
                                onClick={() => { navigate('/changes-approval/') }}
                            >
                                Changes
                            </button>
                            <button
                                className='approval-btn'
                                onClick={() => { navigate('/changes-accepted/') }}
                            >
                                Approved
                            </button>
                            <button
                                className='approval-btn'
                                onClick={() => { navigate('/changes-rejected/') }}
                            >
                                Rejected
                            </button>
                        </Col>
                        {Loading ?
                            <>
                                <div class="wrapper">
                                    <div class="page-loader">
                                        <div class="loading one"></div>
                                        <div class="loading two"></div>
                                        <div class="loading three"></div>
                                        <div class="loading four"></div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                {
                                    AstrologersEditRequest?.data?.data?.length > 0 ?
                                        <Col lg={12} className='d-flex flex-wrap gap-3 changes-approval my-5'>
                                            {
                                                AstrologersEditRequest?.data?.data?.map((item, index) => {
                                                    let indianTime = new Date(item?.request?.createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });
                                                    return (
                                                        <Col lg={6} className='changes-profile p-3'>
                                                            <div className='change-date'>
                                                                <p>{indianTime}</p>
                                                            </div>
                                                            <Col lg={12} className='d-flex justify-content-between'>
                                                                <Col lg={3} className='d-flex justify-content-center'>
                                                                    <div className='requester-profileImg'>
                                                                        <img src={item?.profile ? item?.profile?.file : EmptyProfile} alt="" className='img-fluid' />
                                                                    </div>
                                                                </Col>
                                                                <Col lg={6} className='requester-profileDetail'>
                                                                    <p><span>Name : </span> {item?.astrologer?.name} </p>
                                                                    <p><span>Astrologer ID : </span> {item?.astrologer?.amc_id} </p>
                                                                </Col>
                                                                <Col lg={2} className='d-flex align-items-end justify-content-center'>
                                                                    <button
                                                                        className='approval-btn view-btn'
                                                                        onClick={() => handlePreview(item)}
                                                                    >
                                                                        View
                                                                    </button>
                                                                </Col>
                                                            </Col>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Col>
                                        :
                                        <div className='no-data pt-5'>
                                            <p>No sufficient data available!</p>
                                        </div>
                                }

                                {AstrologersEditRequest?.data?.pagination?.totalPage > 1 ?
                                    <>
                                        <Col lg={12} className='d-flex justify-content-center mt-5 pagination'>
                                            <Pagination className="px-4">
                                                <Pagination.Prev onClick={() => previousPage()} />

                                                {[...new Array(AstrologersEditRequest?.data?.pagination?.totalPage)].map((arr, index) => {
                                                    return (
                                                        <Pagination.Item
                                                            onClick={() => changePage(index + 1)}
                                                            key={index + 1}
                                                            active={index + 1 === Number(currentPage)}
                                                        >
                                                            {index + 1}
                                                        </Pagination.Item>
                                                    )
                                                })}
                                                <Pagination.Next onClick={() => nextPage()} />
                                            </Pagination>
                                        </Col>
                                    </>
                                    : null
                                }

                            </>
                        }
                    </Row>
                </Container >
            }
        </>
    )

};

export default ChangesApprovalList;