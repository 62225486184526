import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';

import MainPagetitle from '../../layouts/MainPagetitle';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetWalletHistory } from '../../../Redux/Slice';


function WalletHistory() {
    const dispatch = useDispatch();
    const { WalletHistory, Loading } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem('admin-auth-token');
    const { id } = useParams();
    const currentUrl = new URL(window.location.href);
    const pathname = currentUrl.pathname;

    const [type, setType] = useState();
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if (id && pathname) {
            if (pathname.includes("user")) {
                dispatch(GetWalletHistory({ token, id, role: "user", page: currentPage ? currentPage : 1 }));
                setType("user");
            }
            else if (pathname.includes("astrologer")) {
                dispatch(GetWalletHistory({ token, id, role: "astrologer", page: currentPage ? currentPage : 1 }));
                setType("astrologer");
            }
        }
    }, [dispatch, token, currentPage, id, pathname])

    useEffect(() => {
        window.scrollTo(0, 0);
        setCurrentPage(WalletHistory?.data?.pagination?.currentpage);
    }, [WalletHistory]);

    const handlePageClick = (event) => {
        const page = Number(event.selected) + 1;
        dispatch(GetWalletHistory({ token, id, page, role: type }))
    }

    return (
        <>
            <MainPagetitle pageTitle="Wallet Transactions" parentTitle="Home" />

            <Container fluid>
                <Row className='d-flex list-main'>
                    {Loading ?
                        <>
                            <div class="wrapper">
                                <div class="page-loader">
                                    <div class="loading one"></div>
                                    <div class="loading two"></div>
                                    <div class="loading three"></div>
                                    <div class="loading four"></div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {
                                WalletHistory?.data?.data?.order?.length > 0 ?
                                    <Col lg={12} className='list-data mt-4 mb-3 p-4'>
                                        <div className='d-flex justify-content-between p-3' >
                                            <h4>AMC ID : {type === "user" ?
                                                WalletHistory?.data?.data?.user?.amc_id
                                                : type === "astrologer" ?
                                                    WalletHistory?.data?.data?.astrologer?.amc_id
                                                    : null}
                                            </h4>
                                            <h4>User Name : {type === "user" ?
                                                WalletHistory?.data?.data?.user?.name
                                                : type === "astrologer" ?
                                                    WalletHistory?.data?.data?.astrologer?.name
                                                    : null}
                                            </h4>
                                        </div>
                                        <table key='list' className="table-100" >
                                            <tr className='transaction-heading'>
                                                <th>Date & Time</th>
                                                <th>Order ID</th>
                                                <th>AMC ID</th>
                                                <th>Duration</th>
                                                <th>Type</th>
                                                <th >Total Amount</th>
                                                <th>Astrologer Share</th>
                                            </tr>
                                            {WalletHistory?.data?.data?.order?.map((data, index) => {
                                                let indianTime = new Date(data?.createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });
                                                let Minute = Math.floor(data?.duration / 60);
                                                let seconds = Math.floor(data?.duration % 60);

                                                return (
                                                    <>
                                                        <tr className='transaction-data'>
                                                            <td>{indianTime}</td>
                                                            <td>{data?.id}</td>
                                                            <td>{data?.user ?
                                                                data?.user?.amc_id
                                                                : data?.astrologer ?
                                                                    data?.astrologer?.amc_id
                                                                    : "none"}
                                                            </td>
                                                            <td>{`${Minute} min ${seconds} sec`}</td>
                                                            <td >{data?.type}</td>
                                                            <td >₹ {data?.totalrate}</td>
                                                            <td >₹ {data?.astrologer_share_cost}</td>
                                                        </tr>
                                                    </>
                                                )
                                            })}
                                        </table>
                                    </Col>
                                    :
                                    <div className='no-data pt-5'>
                                        <p>No sufficient data available!</p>
                                    </div>
                            }

                            {WalletHistory?.data?.pagination?.totalPage > 1 ?
                                <>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=">"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={3}
                                        pageCount={WalletHistory?.data?.pagination?.totalPage}
                                        forcePage={WalletHistory?.data?.pagination?.currentpage - 1}
                                        previousLabel="<"
                                        renderOnZeroPageCount={null}
                                        className='custom-pagination'
                                    />
                                </>
                                : null
                            }
                        </>
                    }

                </Row>
            </Container >
        </>
    )
}

export default WalletHistory;