import { Container, Row, Col } from 'react-bootstrap';
import MainPagetitle from '../../layouts/MainPagetitle';
import { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetEarningsHistory } from '../../../Redux/Slice';

const Earnings = () => {
    const dispatch = useDispatch();
    const token = localStorage.getItem('admin-auth-token');
    const { EarningsHistory } = useSelector((state) => state.Astromind);

    let page = 1;
    const pageLimit = window.innerWidth < 600 ? 15 : 25;
    const [currentPage, setCurrentPage] = useState(1);
    const [balance, setBalance] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        setCurrentPage(EarningsHistory?.data?.pagination?.currentpage);
    }, [EarningsHistory]);

    useEffect(() => {
        dispatch(GetEarningsHistory({ token, page, pageLimit }));
        // dispatch(GetMyWallet(token));
    }, [dispatch, token, page]);

    const handlePageClick = (event) => {
        const page = Number(event.selected) + 1;
        dispatch(GetEarningsHistory({ token, page, pageLimit }));
    }

    useEffect(() => {
        let TotalEarings = Number(EarningsHistory?.data?.data?.totalEarnings);
        let AmcShare = Number(EarningsHistory?.data?.data?.totelAMCEarnings);
        let availableBalance = Number(EarningsHistory?.data?.data?.totelAstrologerEarnings);
        let pgCharge = (availableBalance * 2.5) / 100;
        let subTotal = availableBalance - pgCharge;
        let tdsCharge = (subTotal * 10) / 100;
        let gstCharge = 0;
        let payableAmount = subTotal - tdsCharge + gstCharge;

        setBalance({
            totalEarings: TotalEarings.toFixed(2),
            amcShare: AmcShare.toFixed(2),
            availableBalance: availableBalance.toFixed(2),
            pgCharge: pgCharge.toFixed(2),
            tdsCharge: tdsCharge.toFixed(2),
            gstCharge: gstCharge.toFixed(2),
            payableAmount: payableAmount.toFixed(2),
        });
    }, [EarningsHistory]);

    return (
        <>
            <MainPagetitle pageTitle="Earnings" parentTitle="Home" />
            <Row className='d-flex justify-content-center margin-3' >
                <Col lg={12} sm={12} className='blance-card'>
                    <Col lg={12} className='d-flex mb-3'>
                        <Col lg={6}>
                            <p className='avail-balance'><span>Total Earnings :</span> ₹ {balance?.totalEarings ? balance?.totalEarings : '0.00'}</p>
                        </Col>
                        <Col lg={6}>
                            <p className='avail-balance'><span>AMC Share : </span> ₹ {balance?.amcShare ? balance?.amcShare : '0.00'}</p>
                        </Col>
                    </Col>
                    <Col lg={12} className='d-flex'>
                        <Col lg={6}>
                            <p className='avail-balance'><span>Astrologers Share :</span> ₹ {balance?.availableBalance ? balance?.availableBalance : '0.00'}</p>
                        </Col>
                        <Col lg={6}>
                            <p className='avail-balance'><span>Astrologers Payable Amount : </span> ₹ {balance?.payableAmount ? balance?.payableAmount : '0.00'}</p>
                        </Col>
                    </Col>
                    <Col className='d-flex flex-wrap  gap-2 mt-3'>
                        <Col lg={3} sm={5}>
                            <p><span>PG Charge  :</span> ₹ {balance?.pgCharge ? balance?.pgCharge : '0.00'}</p></Col>
                        <Col lg={3} sm={5}>
                            <p><span>TDS : </span> ₹ {balance?.tdsCharge ? balance?.tdsCharge : '0.00'}</p></Col>
                        <Col lg={2} sm={5}>
                            <p><span>GST : </span> ₹ {balance?.gstCharge ? balance?.gstCharge : '0.00'}</p></Col>
                        {/* <Col lg={3} sm={5}>
                            <p >Payable Amount : <span className='fw-bold text-black'> ₹ {balance?.payableAmount ? balance?.payableAmount : '0.00'}</span></p>
                        </Col> */}
                    </Col>
                </Col>
                <Col lg={12} className='transaction-table mt-5'>
                    {EarningsHistory?.data?.data?.orders?.length > 0 ?
                        <>
                            <table>
                                <tr className='transaction-heading'>
                                    <th>S No</th>
                                    <th>Date & Time</th>
                                    <th>Order Id</th>
                                    <th>Astrologer AMC ID</th>
                                    <th>User AMC ID</th>
                                    <th>Duration</th>
                                    <th>Type</th>
                                    <th>Total Amount</th>
                                    <th>Your Share</th>
                                </tr>
                                {EarningsHistory?.data?.data?.orders?.map((data, index) => {
                                    let indianTime = new Date(data?.createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });
                                    let Minute = Math.floor(data.duration / 60);
                                    const seconds = data.duration % 60;
                                    return (
                                        <>
                                            <tr className='transaction-data'>
                                                <td>
                                                    {
                                                        EarningsHistory?.data?.data?.pagination?.currentpage === 1 ?
                                                            <>
                                                                {index + 1}
                                                            </>
                                                            :
                                                            <>
                                                                {((EarningsHistory?.data?.data?.pagination?.currentpage - 1) * pageLimit) + (index + 1)}
                                                            </>
                                                    }
                                                </td>
                                                <td>{indianTime}</td>
                                                <td>{data.id}</td>
                                                <td>{data.astrologer?.amc_id}</td>
                                                <td>{data.user?.amc_id}</td>
                                                <td>{Minute} min {seconds} sec</td>
                                                <td>{data.type}</td>
                                                <td className='payment-success' ><span className='text-black'>₹</span> + {data.totalrate}</td>
                                                <td className='payment-success' ><span className='text-black'>₹</span> + {data.amc_share_cost}</td>
                                            </tr>
                                        </>
                                    )
                                })}
                            </table>
                        </>
                        :
                        <>
                            <div className='no-data'>
                                <p>No sufficient data available!</p>
                            </div>
                        </>
                    }
                </Col>
                {EarningsHistory?.data?.data?.pagination?.totalPage > 0 ?
                    <>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            pageCount={EarningsHistory?.data?.data?.pagination?.totalPage}
                            forcePage={EarningsHistory?.data?.data?.pagination?.currentpage - 1}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            className='custom-pagination'
                        />
                    </ >
                    :
                    null
                }
            </Row>
        </>
    );
};

export default Earnings;