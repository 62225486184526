
import DashboardIcon from '../../../images/svg/dashboard-icon.svg';
import AstrologerIcon from '../../../images/svg/astrologer-icon.svg';
import UsersIcon from '../../../images/svg/users-icon.svg';
import NewAstrologersIcon from '../../../images/svg/new-astrologer-icon.svg';
import ChangesApprovalIcon from '../../../images/svg/changes-approval-icon.svg';
import RatingIcon from '../../../images/svg/ratings-icon.svg';
import WalletIcon from '../../../images/svg/wallet-icon.svg';
import OfferIcon from '../../../images/svg/offer-icon.svg';
import ManagementIcon from '../../../images/svg/management-icon.svg';
import EmployeeIcon from '../../../images/svg/employee-icon.svg';
import OrderHistoryIcon from '../../../images/svg/Order-history-icon.svg';
import NotificationIcon from '../../../images/svg/notification-icon.svg';
import ReportIcon from '../../../images/svg/report-icon.svg';

export const MenuList = [
    //Dashboard
    {
        title: 'Dashboard',
        classsChange: 'mm-collapse',
        iconStyle: DashboardIcon,
        to: '/dashboard/',
        access: ["Admin", "Support Team", "Management Team", "Marketing Team", "Finance Team",],
    },
    {
        title: 'Employees',
        iconStyle: EmployeeIcon,
        access: ["Admin"],
        to: '/employees/',
    },
    {
        title: 'Astrologers',
        iconStyle: AstrologerIcon,
        access: ["Admin", "Support Team", "Management Team"],
        to: '/all-astrologers/',
    },
    {
        title: 'Users',
        iconStyle: UsersIcon,
        access: ["Admin", "Support Team", "Management Team", "Marketing Team", "Finance Team",],
        content: [

            {
                title: 'Users',
                access: ["Admin", "Support Team", "Management Team", "Marketing Team", "Finance Team",],
                to: '/users/',

            },
            {
                title: 'Unregistered Users',
                access: ["Admin", "Support Team", "Management Team", "Marketing Team", "Finance Team",],
                to: '/unregister-users/',

            },
        ],
    },
    {
        title: 'Order History',
        iconStyle: OrderHistoryIcon,
        access: ["Admin", "Support Team", "Management Team", "Marketing Team", "Finance Team",],
        content: [

            // {
            //     title: 'Chat History',
            //     to: '/call-logs',					
            // },
            {
                title: 'Call Logs',
                access: ["Admin", "Support Team", "Management Team", "Marketing Team", "Finance Team",],
                to: '/order-history/call-logs/',

            },
            {
                title: 'Chat Logs',
                access: ["Admin", "Support Team", "Management Team", "Marketing Team", "Finance Team",],
                to: '/order-history/chat-logs/',

            },
        ],
    },
    {
        title: 'Earnings',
        iconStyle: WalletIcon,
        access: ["Admin", "Support Team", "Management Team", "Finance Team",],
        to: '/earnings/',
    },
    {
        title: 'New Applicant',
        iconStyle: NewAstrologersIcon,
        access: ["Admin", "Management Team"],
        to: '/new-astrologers/',
    },
    {
        title: 'Changes Approval',
        iconStyle: ChangesApprovalIcon,
        access: ["Admin", "Management Team"],
        to: '/changes-approval/',
    },
    {
        title: 'Ratings & Reviews',
        iconStyle: RatingIcon,
        access: ["Admin", "Support Team", "Management Team"],
        to: '/ratings-reviews/',
    },
    {
        title: 'Wallet Page',
        iconStyle: WalletIcon,
        access: ["Admin", "Finance Team", "Support Team", "Management Team"],
        to: '/user-wallet/',
    },
    {
        title: 'Special Offer',
        iconStyle: OfferIcon,
        access: ["Admin", "Marketing Team", "Management Team"],
        to: '/special-offer/',
    },
    {
        title: 'Management',
        classsChange: 'mm-collapse',
        iconStyle: ManagementIcon,
        access: ["Admin", "Marketing Team"],
        content: [
            {
                title: 'Blog List',
                access: ["Admin", "Marketing Team"],
                to: '/blog-list/',
            },
            {
                title: 'Add Blog',
                access: ["Admin", "Marketing Team"],
                to: '/add-blog/',

            },
        ],
    },
    {
        title: 'Notifications',
        classsChange: 'mm-collapse',
        iconStyle: NotificationIcon,
        access: ["Admin", "Support Team", "Management Team", "Marketing Team", "Finance Team",],
        content: [
            {
                title: 'All Notifications',
                access: ["Admin", "Support Team", "Management Team", "Marketing Team", "Finance Team",],
                to: '/notifications/',
            },
            {
                title: 'Sent Notifications',
                access: ["Admin", "Support Team", "Management Team","Finance Team",],
                to: '/sent-notifications/',
            },
            {
                title: 'Create Notification',
                access: ["Admin", "Support Team", "Management Team", "Finance Team",],
                to: '/create-notification/',

            },
        ],
    },
    {
        title: 'Reports',
        classsChange: 'mm-collapse',
        iconStyle: ReportIcon,
        access: ["Admin", "Support Team", "Management Team", "Finance Team",],
        content: [
            {
                title: 'Astrologer Payout Report',
                access: ["Admin", "Support Team", "Management Team", "Finance Team",],
                to: '/astrologer-payout-report/',
            },
            {
                title: 'Amc Income Report',
                access: ["Admin", "Support Team", "Management Team", "Finance Team",],
                to: '/amc-income-report/',
            },
            {
                title: 'Amc Sales Refund Report',
                access: ["Admin", "Support Team", "Management Team", "Finance Team",],
                to: '/amc-sales-refund-report/',
            },
        ],
    },

]