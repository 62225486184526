import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { AiFillEdit } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";

import MainPagetitle from '../../layouts/MainPagetitle';
import EditWallet from './EditWallet';
import ChangeWalletPin from './ChangeWalletPin';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetWalletAstrologersOrUsers, successMessage, errorMessage } from '../../../Redux/Slice';

import ReactPaginate from 'react-paginate';

function AstrologerWallet() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { AdminProfile, Wallet, Loading, success, errors } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem('admin-auth-token');
    const role = "astrologer";

    const [currentPage, setCurrentPage] = useState(Wallet?.data?.pagination?.currentpage);
    const pageLimit = window.innerWidth < 600 ? 15 : 25;
    const [isEditWallet, setIsEditWallet] = useState(false);
    const [isChangeWalletPin, setIsChangeWalletPin] = useState(false);
    const [editData, setEditData] = useState();
    const [searchValue, setSearchValue] = useState(null);

    const EditClose = (value) => {
        setIsEditWallet(value);
        setIsChangeWalletPin(value);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if (Wallet?.data) {
            setCurrentPage(Wallet?.data?.pagination?.currentpage);
        }
    }, [Wallet, currentPage]);

    useEffect(() => {
        dispatch(GetWalletAstrologersOrUsers({ token, page: currentPage ? currentPage : 1, pageLimit, role }))
    }, [dispatch, token, role]);

    useEffect(() => {
        if (searchValue) {
            dispatch(GetWalletAstrologersOrUsers({ token, page: 1, pageLimit, role, search: searchValue }));
        }
        else if (searchValue === '') {
            dispatch(GetWalletAstrologersOrUsers({ token, page: 1, pageLimit, role }));
        }
    }, [dispatch, token, searchValue])

    const handlePageClick = (event) => {
        const page = Number(event.selected) + 1;
        if (searchValue) {
            dispatch(GetWalletAstrologersOrUsers({ token, page, pageLimit, role, search: searchValue }));
        }
        else {
            dispatch(GetWalletAstrologersOrUsers({ token, page, pageLimit, role }));
        }
    }

    const handleViewHistory = (id) => {
        navigate(`/astrologer-wallet-transactions/${id}`);
    }

    const EditWalletAmount = (data) => {
        Swal.fire({
            title: "Do you want to Edit the Astrologer Wallet",
            confirmButtonColor: "#36AA00",
            confirmButtonText: "Edit",
            showCancelButton: true,
            cancelButtonColor: "#025BFD",
        }).then((result) => {
            if (result?.isConfirmed) {
                setIsEditWallet(true);
                setEditData(data);
            }
        })
    }

    useEffect(() => {
        if (success?.status === 200) {
            if (success?.data?.data?.isValidated) {
                setIsEditWallet(true);
                dispatch(successMessage(' '))
            }
            else if (success?.data?.data?.isChanged) {
                Swal.fire({
                    title: success?.data?.message,
                    icon: "success",
                    iconColor: "#36AA00",
                    confirmButtonColor: "#36AA00",
                    confirmButtonText: "Okay",
                    timer: 5000
                }).then(() => {
                    setIsChangeWalletPin(false);
                    dispatch(successMessage(' '));
                })
            }
            else {
                dispatch(GetWalletAstrologersOrUsers({ token, page: currentPage, role }))
                setIsEditWallet(false);
                Swal.fire({
                    title: success?.data?.message,
                    icon: "success",
                    iconColor: "#36AA00",
                    confirmButtonColor: "#36AA00",
                    confirmButtonText: "OK"
                }).then((result) => {
                    if (result?.isConfirmed) {
                        dispatch(successMessage(''));
                    }
                })
            }
        }
        else if (errors) {
            dispatch(GetWalletAstrologersOrUsers({ token, page: currentPage, role }))
            Swal.fire({
                title: errors?.message,
                icon: "error",
                iconColor: "#CA0505",
                confirmButtonColor: "#CA0505",
                confirmButtonText: "Okay"
            }).then((result) => {
                if (result?.isConfirmed) {
                    dispatch(errorMessage({ errors: '' }));
                }
            })
        }
    }, [dispatch, token, currentPage, success, errors]);

    return (
        <>
            {
                isEditWallet ?
                    <EditWallet
                        show={isEditWallet}
                        close={EditClose}
                        data={editData}
                        role={role}
                    />
                    : null
            }
            {
                isChangeWalletPin ?
                    <ChangeWalletPin
                        show={isChangeWalletPin}
                        close={EditClose}
                    />
                    : null
            }
            <Container fluid>
                <Row className='d-flex list-main'>
                    <Col lg={12} className='list-main'>
                        <MainPagetitle pageTitle="Astrologer Wallet" parentTitle="Home" />
                    </Col>
                    <Col lg={12} className='d-flex justify-content-center gap-3 mt-5 wallet-buttons'>
                        <button
                            className='approval-btn'
                            onClick={() => {
                                setCurrentPage(null)
                                navigate('/user-wallet/')
                            }}
                        >
                            User Wallet
                        </button>
                        <button
                            className='approval-btn bg-yellow text-white'
                            onClick={() => {
                                setCurrentPage(null)
                                navigate('/astrologer-wallet/')
                            }}
                        >
                            Astrologer Wallet
                        </button>
                        {
                            AdminProfile?.data?.data?.role?.rolename === "Admin" ?
                                <button
                                    className='change-pin'
                                    onClick={() => {
                                        setIsChangeWalletPin(true);
                                    }}
                                >
                                    Change Pin
                                </button>
                                : null
                        }
                    </Col>

                    <Col lg={12} sm={12} className='d-flex flex-wrap pt-4'>
                        <Col lg={3} sm={3} className=''>
                            <h3 className='fw-600'>Astrologer Wallet</h3>
                        </Col>
                        <Col lg={9} sm={9} className="d-flex flex-nowrap justify-content-end gap-3 filter-nav">
                            <div className="d-flex text-white search-option wallet-search-option">
                                <input
                                    type="text"
                                    value={searchValue}
                                    onChange={(e) => { setSearchValue(e.target.value) }}
                                    placeholder='Search AMC ID, Phone Number, Name'
                                />
                                {
                                    searchValue ?
                                        <div
                                            className="d-flex align-items-center p-2 cancel-btn"
                                            onClick={() => { setSearchValue('') }}
                                        >
                                            <IoClose />
                                        </div>
                                        :
                                        <div className="d-flex justify-content-center p-2 search-btn">
                                            <FaSearch />
                                        </div>
                                }
                            </div>
                        </Col>
                    </Col>
                    {Loading ?
                        <>
                            <div class="wrapper">
                                <div class="page-loader">
                                    <div class="loading one"></div>
                                    <div class="loading two"></div>
                                    <div class="loading three"></div>
                                    <div class="loading four"></div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {
                                Wallet?.data?.data?.length > 0 ?

                                    <Col lg={12} className='list-data mt-4 mb-3'>
                                        <table key='list' className="table-100" >
                                            <tr>
                                                <th>S No</th>
                                                <th>AMC ID</th>
                                                <th>Name</th>
                                                <th className='responsive-hide'>Phone</th>
                                                <th >Aval Balance</th>
                                                <th>History</th>
                                                <th></th>
                                            </tr>
                                            {Wallet?.data?.data?.map((data, index) => (
                                                <>
                                                    <tr>
                                                        <td>
                                                            {
                                                                Wallet?.data?.pagination?.currentpage === 1 ?
                                                                    <>
                                                                        {index + 1}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {((Wallet?.data?.pagination?.currentpage - 1) * pageLimit) + (index + 1)}
                                                                    </>
                                                            }
                                                        </td>
                                                        <td>{data?.astrologer?.amc_id}</td>
                                                        <td>{data?.astrologer?.name}</td>
                                                        <td className='responsive-hide'>+{data?.astrologer?.phone}</td>
                                                        <td >₹ {data?.astrologer?.mywallet}</td>
                                                        <td
                                                            className='view'
                                                            onClick={() => { handleViewHistory(data?.astrologer?.id) }}
                                                        >
                                                            view details
                                                        </td>
                                                        <td >
                                                            <button
                                                                className='yellow-btn'
                                                                onClick={() => { EditWalletAmount(data?.astrologer) }}
                                                            >
                                                                <AiFillEdit /> Edit
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </>
                                            ))}
                                        </table>
                                    </Col>
                                    :
                                    <div className='no-data pt-5'>
                                        <p>No sufficient data available!</p>
                                    </div>
                            }

                            {Wallet?.data?.pagination?.totalPage > 1 ?
                                <>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=">"
                                        onPageChange={(event) => handlePageClick(event)}
                                        pageRangeDisplayed={3}
                                        pageCount={Wallet?.data?.pagination?.totalPage}
                                        forcePage={Wallet?.data?.pagination?.currentpage - 1}
                                        previousLabel="<"
                                        renderOnZeroPageCount={null}
                                        className='custom-pagination'
                                    />
                                </>
                                : null
                            }
                        </>
                    }
                </Row>
            </Container >
        </>
    )
}

export default AstrologerWallet;