import { Container, Row, Col, Button } from 'react-bootstrap';
import MainPagetitle from '../../layouts/MainPagetitle';
import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import DatePicker from "react-datepicker";
import moment from 'moment';

import Select from 'react-select';
import { MultiSelect } from "react-multi-select-component";

import { FaDownload } from "react-icons/fa6";

import ViewDownloadReports from './ViewDownloadReports';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetAllUsersList, GetAmcIncomeReport, GetAllDownloadReports } from '../../../Redux/Slice';

const state = [
    { value: 'AN', label: 'Andaman and Nicobar Islands' },
    { value: 'AP', label: 'Andhra Pradesh' },
    { value: 'AR', label: 'Arunachal Pradesh' },
    { value: 'AS', label: 'Assam' },
    { value: 'BR', label: 'Bihar' },
    { value: 'CG', label: 'Chandigarh' },
    { value: 'CH', label: 'Chhattisgarh' },
    { value: 'DN', label: 'Dadra and Nagar Haveli' },
    { value: 'DD', label: 'Daman and Diu' },
    { value: 'DL', label: 'Delhi' },
    { value: 'GA', label: 'Goa' },
    { value: 'GJ', label: 'Gujarat' },
    { value: 'HR', label: 'Haryana' },
    { value: 'HP', label: 'Himachal Pradesh' },
    { value: 'JK', label: 'Jammu and Kashmir' },
    { value: 'JH', label: 'Jharkhand' },
    { value: 'KA', label: 'Karnataka' },
    { value: 'KL', label: 'Kerala' },
    { value: 'LA', label: 'Ladakh' },
    { value: 'LD', label: 'Lakshadweep' },
    { value: 'MP', label: 'Madhya Pradesh' },
    { value: 'MH', label: 'Maharashtra' },
    { value: 'MN', label: 'Manipur' },
    { value: 'ML', label: 'Meghalaya' },
    { value: 'MZ', label: 'Mizoram' },
    { value: 'NL', label: 'Nagaland' },
    { value: 'OR', label: 'Odisha' },
    { value: 'PY', label: 'Puducherry' },
    { value: 'PB', label: 'Punjab' },
    { value: 'RJ', label: 'Rajasthan' },
    { value: 'SK', label: 'Sikkim' },
    { value: 'TN', label: 'Tamil Nadu' },
    { value: 'TS', label: 'Telangana' },
    { value: 'TR', label: 'Tripura' },
    { value: 'UP', label: 'Uttar Pradesh' },
    { value: 'UK', label: 'Uttarakhand' },
    { value: 'WB', label: 'West Bengal' },
]

const field = [
    { value: 'createdAt', label: 'Date' },
    { value: 'payment_id', label: 'Payment Id' },
    { value: 'total_amount', label: 'Total Amount' },
    { value: 'deposit_amount', label: 'Deposit Amount' },
    { value: 'bonus', label: 'Bonus' },
    { value: 'gst', label: 'Gst' },
    { value: 'status', label: 'Status' },
    { value: 'Payment_method', label: 'Payment Method' },
    { value: 'invoice_id', label: 'Invoice Id' },
    { value: 'invoice_url', label: 'Invoice Url' },
    { value: 'email', label: 'Email' },
    { value: 'contact', label: 'Phone Mo' },
    { value: 'address', label: 'Address' },
    { value: 'state', label: 'State' },
    { value: 'country', label: 'Country' },
]

const timePeriod = [
    { value: 'Today', label: 'Today' },
    { value: 'Yesterday', label: 'Yesterday' },
    { value: 'Last-7', label: 'Last 7 days' },
    { value: 'Last-15', label: 'Last 15 days' },
    { value: 'Last-30', label: 'Last 30 days' },
    { value: 'Current-month', label: 'Current Month' },
    { value: 'Previous-month', label: 'Previous Month' },
    { value: 'Custom', label: 'Custom' },
    { value: ' ', label: ' ' },
]

function AmcIncomeReport() {
    const dispatch = useDispatch();
    const token = localStorage.getItem('admin-auth-token');
    const { Loading, ReportUsersList, AmcIncomeReportData, ReportDownloads } = useSelector((state) => state.Astromind);

    console.log(AmcIncomeReportData);

    const payoutData = AmcIncomeReportData?.data?.data?.transactions[0];
    const tableKeys = payoutData ? Object.keys(payoutData) : null;

    const [stateSelected, setStateSelected] = useState([]);
    const [usersOption, setUsersOption] = useState([{}]);
    const [usersSelected, setUsersSelected] = useState([]);

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [selected, setSelected] = useState([
        { value: 'createdAt', label: 'Date' },
        { value: 'rzr_id', label: 'Payment Id' },
        { value: 'total_amount', label: 'Total Amount' },
        { value: 'deposit_amount', label: 'Deposit Amount' },
        { value: 'bonus', label: 'Bonus' },
        { value: 'gst', label: 'Gst' },
        { value: 'status', label: 'Status' },
        { value: 'Payment_method', label: 'Payment Method' },
        { value: 'invoice_id', label: 'Invoice Id' },
        { value: 'invoice_url', label: 'Invoice Url' },
    ]);
    const [time_period, setTime_period] = useState();
    const [viewDownloads, setViewDownloads] = useState(false);
    const [fileName, setFileName] = useState();

    const hideDownloads = (value) => {
        setViewDownloads(value)
    }

    useEffect(() => {
        dispatch(GetAllUsersList({ token }));
    }, [dispatch, token])

    console.log(ReportUsersList);

    useEffect(() => {
        if (ReportUsersList?.data?.data) {
            const options = ReportUsersList?.data?.data?.map((value) => ({
                value: value?.id,
                label: `${value?.amc_id} - ${value?.name}`
            }));
            setUsersOption(options);
        }
    }, [ReportUsersList])

    useEffect(() => {
        if (startDate) {
            formik.setFieldValue('start_date', moment(startDate).format('YYYY-MM-DD'));
        }
        if (endDate) {
            formik.setFieldValue('end_date', moment(endDate).format('YYYY-MM-DD'));
        }
        if (selected) {
            formik.setFieldValue('field', selected);
        }
        // if (usersSelected?.length > 0) {
        //     formik.setFieldValue('astrologers_id', usersSelected);
        // }
    }, [startDate, endDate, selected, usersSelected]);

    const formik = useFormik({
        initialValues: {
            state: '',
            users_id: [],
            start_date: '',
            end_date: '',
            field: []
        },
        onSubmit: async (values) => {

            let isAll = state?.length === stateSelected?.length ? true : false;
            let selectedState = [];
            if (!isAll) {
                stateSelected?.map((item) => {
                    selectedState.push(item?.value);
                })
            }

            let Start_Date = moment(values.start_date).format('YYYY-MM-DD');
            let End_Date = moment(values.end_date).format('YYYY-MM-DD');

            if (time_period?.value === 'Current-month' || time_period?.value === 'Previous-month') {
                let month = values.start_date.getMonth() + 1;
                let year = values.start_date.getFullYear();
                let firstDay = new Date(year, month - 1, 1);
                let lastDay = new Date(year, month, 0);

                Start_Date = moment(firstDay).format('YYYY-MM-DD');
                End_Date = moment(lastDay).format('YYYY-MM-DD');
            }

            let selected_field = [];

            selected?.map((data) => {
                selected_field.push(data.value)
            })
            const incomeReportDetails = {
                startDate: Start_Date,
                endDate: End_Date !== 'Invalid date' ? End_Date : Start_Date,
                states: selectedState,
                isAlldata: isAll,
                fields: selected_field,
            }
            dispatch(GetAmcIncomeReport({ token, incomeReportDetails }));
        },
        validate: (values) => {
            const errors = {};

            if (stateSelected?.length <= 0) {
                errors.state = "Requried*";
            }
            // if (usersSelected?.length <= 0) {
            //     errors.users_id = "Requried*";
            // }
            if (!time_period?.value) {
                errors.time_period = "Requried*";
            }
            if ((time_period?.value === "Custom") && !values.start_date) {
                errors.start_date = "Requried*";
            }
            if ((time_period?.value === "Custom") && !values.end_date) {
                errors.end_date = "Requried*";
            }
            if (selected?.length <= 0) {
                errors.field = "Requried*";
            }

            return errors;
        }
    })

    useEffect(() => {
        const today = new Date();
        if (time_period?.value === "Today") {
            formik.setFieldValue('start_date', today);
        }
        else if (time_period?.value === "Yesterday") {
            const yesterday = new Date(today);

            yesterday.setDate(today.getDate() - 1);
            formik.setFieldValue('start_date', yesterday);
        }
        else if (time_period?.value === "Last-7" || time_period?.value === "Last-15" || time_period?.value === "Last-30") {
            const pastday = new Date(today);
            const pastdayCount = time_period?.value === "Last-7" ?
                7
                : time_period?.value === "Last-15" ?
                    15
                    : time_period?.value === "Last-30" ?
                        30
                        : null;
            pastday.setDate(today.getDate() - pastdayCount);
            formik.setFieldValue('start_date', pastday);
            formik.setFieldValue('end_date', today);
        }
        else if (time_period?.value === "Current-month") {
            console.log(today.getMonth());
            console.log(today);

            formik.setFieldValue('start_date', today);
        }
        else if (time_period?.value === "Previous-month") {
            const pastmonth = new Date(today);
            pastmonth.setMonth(today.getMonth() - 1);
            console.log(pastmonth);

            formik.setFieldValue('start_date', pastmonth);
        }
    }, [time_period])

    useEffect(() => {
        if (AmcIncomeReportData?.data?.data?.reportLink) {
            const reportLink = AmcIncomeReportData?.data?.data?.reportLink;
            const a = document.createElement('a');
            a.href = reportLink;
            a.download = reportLink.split('/').pop();
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    }, [AmcIncomeReportData])

    const handleExport = () => {
        let isAll = state?.length === stateSelected?.length ? true : false;
        let selectedState = [];
        if (!isAll) {
            stateSelected?.map((item) => {
                selectedState.push(item?.value);
            })
        }

        let Start_Date = moment(formik.values.start_date).format('YYYY-MM-DD');
        let End_Date = moment(formik.values.end_date).format('YYYY-MM-DD');

        if (time_period?.value === 'Current-month' || time_period?.value === 'Previous-month') {
            let month = formik.values.start_date.getMonth() + 1;
            let year = formik.values.start_date.getFullYear();
            let firstDay = new Date(year, month - 1, 1);
            let lastDay = new Date(year, month, 0);

            Start_Date = moment(firstDay).format('YYYY-MM-DD');
            End_Date = moment(lastDay).format('YYYY-MM-DD');
        }

        let selected_field = [];

        selected?.map((data) => {
            selected_field.push(data.value)
        })
        const incomeReportDetails = {
            startDate: Start_Date,
            endDate: End_Date !== 'Invalid date' ? End_Date : Start_Date,
            states: selectedState,
            isAlldata: isAll,
            fields: selected_field,
            isExportNeeded: 1,
            fileName: fileName
        }
        dispatch(GetAmcIncomeReport({ token, incomeReportDetails }));
    }

    const handleViewDownloads = () => {
        setViewDownloads(true);
        dispatch(GetAllDownloadReports({ token, isIncomeReport: true }));
    }

    return (
        <>
            <MainPagetitle pageTitle="AMC Income Report" parentTitle="Home" />
            <Row className='d-flex justify-content-center margin-3' >
                <Col lg={12} className='add-offer-card'>
                    <div className="filter cm-content-box box-primary">
                        <div className="content-title">
                            <div className="cpa">
                                Details
                            </div>

                        </div>
                        <div className="cm-content-body form excerpt">
                            <div className="card-body">
                                <Row className='report-filter'>
                                    <Col xl={4} sm={6} className="mb-4" >
                                        <label className="from-label">State <span className="text-red">*</span></label>
                                        <MultiSelect
                                            options={state}
                                            value={stateSelected}
                                            onChange={setStateSelected}
                                            labelledBy="Select Options"
                                        />
                                        <p className={"error__feedback pt-2 mb-0"}>{formik.errors.state}</p>
                                    </Col>
                                    {/* <Col xl={4} sm={6} className="mb-4" >
                                        <label className="from-label">User ID <span className="text-red">*</span></label>
                                        <MultiSelect
                                            options={usersOption}
                                            value={usersSelected}
                                            onChange={setUsersSelected}
                                            labelledBy="Select Options"
                                        />
                                        <p className={"error__feedback pt-2 mb-0"}>{formik.errors.users_id}</p>
                                    </Col> */}
                                    <Col xl={4} sm={6} className="mb-4" >
                                        <label className="from-label">Time Period<span className="text-red">*</span></label>
                                        <Select
                                            options={timePeriod}
                                            className="custom-react-select"
                                            value={time_period}
                                            onChange={(value) => { setTime_period(value) }}
                                        />
                                        <p className={"error__feedback pt-2 mb-0"}>{formik.errors.time_period}</p>
                                    </Col>
                                    {
                                        time_period?.value === "Today" || time_period?.value === "Yesterday" ?
                                            <Col xl={4} sm={6} className="mb-4" >
                                                <label className="from-label">Date</label>
                                                <DatePicker
                                                    className="report-date-picker"
                                                    selected={formik?.values?.start_date}
                                                    showIcon
                                                    disabled={true}
                                                />
                                            </Col>
                                            : null
                                    }
                                    {
                                        time_period?.value === "Last-7" || time_period?.value === "Last-15" || time_period?.value === "Last-30" ?
                                            <Col xl={4} sm={6} className="mb-4" >
                                                <label className="from-label">Date Range</label>
                                                <DatePicker
                                                    className="report-date-picker"
                                                    selectsRange={true}
                                                    startDate={formik?.values?.start_date}
                                                    endDate={formik?.values?.end_date}
                                                    showIcon
                                                    disabled={true}
                                                />
                                            </Col>
                                            : null
                                    }
                                    {
                                        time_period?.value === "Current-month" || time_period?.value === "Previous-month" ?
                                            <Col xl={4} sm={6} className="mb-4" >
                                                <label className="from-label">Month</label>
                                                <DatePicker
                                                    className="report-date-picker"
                                                    selected={formik?.values?.start_date}
                                                    dateFormat="MM/yyyy"
                                                    showIcon
                                                    disabled={true}
                                                />
                                            </Col>
                                            : null
                                    }
                                    {
                                        time_period?.value === "Custom" ?
                                            <Col xl={4} sm={6} className="mb-4" >
                                                <label className="from-label">Date Range </label>
                                                <DatePicker
                                                    placeholderText='select date range'
                                                    className="report-date-picker"
                                                    selectsRange={true}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    onChange={(update) => {
                                                        setDateRange(update);
                                                    }}
                                                    isClearable={true}
                                                    monthsShown={2}
                                                    showIcon
                                                    maxDate={new Date()}
                                                />
                                                <p className={"error__feedback pt-2 mb-0"}>{formik.errors.end_date || formik.errors.start_date}</p>
                                            </Col>
                                            : null
                                    }
                                    {
                                        timePeriod.some(period => period.value === time_period?.value) ?
                                            null
                                            :
                                            <Col xl={4} sm={6} className="mb-4" >
                                                <label className="from-label">Date</label>
                                                <DatePicker
                                                    placeholderText='selected date'
                                                    className="report-date-picker"
                                                    selected={formik?.values?.start_date}
                                                    showIcon
                                                    disabled={true}
                                                />
                                                <p className={"error__feedback pt-2 mb-0"}>{formik.errors.start_date}</p>
                                            </Col>
                                    }

                                    <Col xl={4} sm={6} className="mb-4" >
                                        <label className="from-label">Field <span className="text-red">*</span></label>
                                        <MultiSelect
                                            options={field}
                                            value={selected}
                                            onChange={setSelected}
                                            labelledBy="Select Options"
                                        />
                                        <p className={"error__feedback pt-2 mb-0"}>{formik.errors.field}</p>
                                    </Col>

                                    <Col xl={8} sm={12} className='d-flex align-items-center justify-content-end gap-3'>
                                        <Button
                                            type="submit"
                                            variant="primary"
                                            className="mt-3 mt-sm-0"
                                            onClick={() => {
                                                formik.handleSubmit()
                                            }}>
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </Col>

                {
                    viewDownloads ?
                        <>
                            <ViewDownloadReports hideDownloads={hideDownloads} />
                        </>
                        :
                        <>
                            <Col lg={12} sm={12} className='blance-card mt-5 py-3'>
                                <Col lg={12} className='mb-3 view-download'>
                                    <div onClick={handleViewDownloads}>
                                        <a className='text-primary'>
                                            View Downloads
                                        </a>
                                    </div>
                                </Col>
                                {Loading ?
                                    null
                                    :
                                    <>
                                        {
                                            AmcIncomeReportData?.data?.data?.transactions?.length > 0 ?
                                                <>
                                                    <Col lg={12} className='d-flex'>
                                                        <Col lg={7}>
                                                            {/* <Col lg={12} className='d-flex justify-content-center'>
                                                                <Col lg={5}>
                                                                    <p className='avail-balance' ><span>Amc Total Income :</span> ₹ {AmcIncomeReportData?.data?.data?.overAllData?.totalEarnings ? AmcIncomeReportData?.data?.data?.overAllData?.totalEarnings : '0.00'}</p>
                                                                </Col>
                                                                <Col lg={3} sm={5}>
                                                                    <p className='avail-balance' ><span>GST : </span> ₹ {'0.00'}</p>
                                                                </Col>
                                                                <Col lg={4} sm={5}>
                                                                    <p className='avail-balance' ><span> Payable Amount : </span> ₹ {AmcIncomeReportData?.data?.data?.overAllData?.totalAstrologerPayableAmount ? AmcIncomeReportData?.data?.data?.overAllData?.totalAstrologerPayableAmount : '0.00'}</p>
                                                                </Col>
                                                            </Col> */}
                                                        </Col>
                                                        <Col lg={5} className='d-flex justify-content-end align-items-end gap-2'>
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    placeholder='Enter your file name(opt)'
                                                                    className='file-name'
                                                                    value={fileName}
                                                                    onChange={(e) => setFileName(e.target.value)}
                                                                />
                                                            </div>
                                                            <Button className='export-button' onClick={handleExport}>
                                                                <FaDownload className='export-icon' /> Export
                                                            </Button>
                                                        </Col>
                                                    </Col>


                                                </>
                                                : null
                                        }
                                    </>
                                }
                            </Col>
                            {Loading ?
                                <>
                                    <div class="wrapper mt-5">
                                        <div class="page-loader">
                                            <div class="loading one"></div>
                                            <div class="loading two"></div>
                                            <div class="loading three"></div>
                                            <div class="loading four"></div>
                                        </div>
                                    </div>
                                </>
                                :
                                <Col
                                    lg={12}
                                    className={`transaction-table report-table ${tableKeys?.length > 10 ? "extent-table-max" : tableKeys?.length > 7 ? "extent-table-center" : tableKeys?.length > 3 ? "extent-table-min" : null} `}
                                >
                                    {AmcIncomeReportData?.data?.data?.transactions?.length > 0 ?
                                        <>
                                            <table>
                                                <tr className='transaction-heading'>
                                                    <th>S No</th>
                                                    {
                                                        tableKeys.includes("createdAt") ?
                                                            <th>Date</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("payment_id") ?
                                                            <th>Payment Id</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("total_amount") ?
                                                            <th>Total Amount</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("gst") ?
                                                            <th>Gst</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("deposit_amount") ?
                                                            <th>Deposit Amount</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("bonus") ?
                                                            <th>Bonus</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("status") ?
                                                            <th>Status</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("Payment_method") ?
                                                            <th>Payment Method</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("invoice_id") ?
                                                            <th>Invoice Id</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("invoice_url") ?
                                                            <th>Invoice Url</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("email") ?
                                                            <th>Email</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("contact") ?
                                                            <th>Phone No</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("address") ?
                                                            <th>Address</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("state") ?
                                                            <th>State</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("country") ?
                                                            <th>Country</th>
                                                            : null
                                                    }
                                                </tr>
                                                {AmcIncomeReportData?.data?.data?.transactions?.map((data, i) => {
                                                    return (
                                                        <>
                                                            <tr className='transaction-data'>
                                                                <td>{i + 1}</td>
                                                                {
                                                                    tableKeys.includes("createdAt") ?
                                                                        <td>
                                                                            {data.createdAt ?
                                                                                new Date(data?.createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })
                                                                                : "none"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("payment_id") ?
                                                                        <td>
                                                                            {data.payment_id ?
                                                                                data.payment_id
                                                                                : "none"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("total_amount") ?
                                                                        <td>₹
                                                                            {data.total_amount ?
                                                                                data.total_amount
                                                                                : "none"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("gst") ?
                                                                        <td>₹
                                                                            {data.gst ?
                                                                                data.gst
                                                                                : "0"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("deposit_amount") ?
                                                                        <td>₹
                                                                            {data.deposit_amount ?
                                                                                data.deposit_amount
                                                                                : "0"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("bonus") ?
                                                                        <td>₹
                                                                            {data.bonus ?
                                                                                data.bonus
                                                                                : "0"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("status") ?
                                                                        <td>
                                                                            {data.status ?
                                                                                data.status
                                                                                : "none"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("Payment_method") ?
                                                                        <td>
                                                                            {data.Payment_method ?
                                                                                data.Payment_method
                                                                                : "none"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("invoice_id") ?
                                                                        <td>
                                                                            {data.invoice_id ?
                                                                                data.invoice_id
                                                                                : "none"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("invoice_url") ?
                                                                        <td>
                                                                            {data.invoice_url ?
                                                                                <a href={data.invoice_url} >Download</a>
                                                                                : "none"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("email") ?
                                                                        <td>
                                                                            {data.email ?
                                                                                data.email
                                                                                : "none"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("contact") ?
                                                                        <td>
                                                                            {data.contact ?
                                                                                data.contact
                                                                                : "none"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("address") ?
                                                                        <td>
                                                                            {data.address ?
                                                                                data.address
                                                                                : "none"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("state") ?
                                                                        <td>
                                                                            {data.state ?
                                                                                data.state
                                                                                : "none"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("country") ?
                                                                        <td>
                                                                            {data.country ?
                                                                                data.country
                                                                                : "none"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                            </tr>
                                                        </>
                                                    )
                                                })}
                                            </table>
                                        </>
                                        :
                                        <>
                                            <div className='no-data'>
                                                <p>No sufficient data available!</p>
                                            </div>
                                        </>
                                    }
                                </Col>
                            }
                        </>

                }
            </Row>
        </>
    );
}

export default AmcIncomeReport;